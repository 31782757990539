<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="headline" v-if="view.id">
          {{ $t('print.edittitle') }}
        </v-card-title>
        <v-card-title class="headline" v-if="!view.id">
          {{ $t('print.addtitle') }}
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" lazy-validation>
            <v-text-field
              v-model="view.name"
              :label="$t('print.name')"
              required
              :rules="[(v) => !!v || $t('all.fieldnoempty')]"
            ></v-text-field>
            <div id="editor1">
              <Vueditor ref="editor"></Vueditor>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" v-if="btnDel" @click.native="del">
            {{ $t('all.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="tagForm = true"> {{ $t('print.tag') }} </v-btn>
          <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
          <v-btn color="primary" dark @click.native="save">
            {{ $t('all.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="tagForm" scrolling>
      <v-card>
        <v-card-title class="headline">
          {{ $t('print.availabletag') }}
        </v-card-title>
        <v-card-text>
          [ФИОКлиента]<br />
          [НомерКлиента]<br />
          [Телефон] <br />
          [Адрес]<br />
          [НомерЗаказа]<br />
          [ДатаЗаказа]<br />
          [ДатаГотовности]<br />
          [ФИООтветственного]<br />
          [ДанныеЗаказа]<br />
          [Оплачено]<br />
          [Скидка]<br />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="tagForm = false"> {{ $t('all.close') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//TODO:перевести теги
export default {
  props: ['title', 'view'],
  data() {
    return {
      dialog: true,
      valid: false,
      resources: [],
      iname: '',
      irule: '',
      errName: false,
      errRes: false,
      btnDel: false,
      tagForm: false,
    }
  },
  computed: {},
  mounted: function () {
    var t = this
    this.iname = this.view.name ? this.view.name : ''
    this.btnDel = this.view.id ? true : false
    this.$refs.editor.setContent(' ')
    if (this.view.id > 0) {
      this.$http
        .post(this.$store.state.apiUrl + 'docprint/get/' + this.view.id)
        .then(
          (response) => {
            var txt = t.escapeHtml(response.body.tpl)
            t.$refs.editor.setContent(txt)
          },
          (err) => {
            console.log(err)
          }
        )
    }
  },
  methods: {
    del: function () {
      console.log(123)
      this.$emit('delete', this.view)
    },
    escapeHtml: function (str) {
      if (typeof str == 'string') {
        str = str.replace(/&gt;/gi, '>')
        str = str.replace(/&lt;/gi, '<')
        str = str.replace(/&#039;/g, "'")
        str = str.replace(/&quot;/gi, '"')
        str = str.replace(/&amp;/gi, '&') /* must do &amp; last */
      }
      return str
    },
    save: function () {
      var i = 0,
        prmRes = [],
        t = this

      var arr = Object.assign({}, this.view)
      for (var res in this.resources) {
        if (this.resources[res].checked == true) {
          prmRes[i] = this.resources[res].id
          i++
        }
      }
      if (this.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'docprint/save/', {
            id: arr.id,
            name: arr.name,
            tpl: this.$refs.editor.getContent(),
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style>
#editor1 {
  width: 100%;
  height: 400px;
}
</style>
