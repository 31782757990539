<template>
  <div>
    <h3>{{ $t('print.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />
    <v-btn dark color="success" @click="addRule" small> <v-icon dark>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('print.name') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tableData" :key="item.id" @click="editRule(item)" style="cursor: pointer">
          <td>{{ item.name }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <modal_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteRule"
    ></modal_edit>
    <modal_delete v-if="modal_delete" v-bind:view="modalView" v-on:close="modalDelClose"></modal_delete>
  </div>
</template>

<script>
import modal_edit from './print_edit.vue'
import modal_delete from './print_delete.vue'

export default {
  data() {
    return {
      tableData: ['load'],
      modal: '',
      modal_delete: '',
      modalTitle: '',
      modalView: '',
    }
  },
  components: {
    modal_edit,
    modal_delete,
  },
  mounted: function() {
    this.dataLoad()
  },
  methods: {
    modalClose: function(prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    modalDelClose: function(prm) {
      this.modal_delete = ''
      if (prm == 'reload') {
        this.modal = ''
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'docprint').then(
        response => {
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    addRule: function() {
      this.modal = true
      this.modalView = []
      //                        this.showModal = true;
      //                        this.modalContent = this.tpl_edit;
      //                      $('#'+this.tag).modal('show')
    },
    editRule: function(view) {
      this.modal = true
      this.modalView = view
    },
    deleteRule: function(view) {
      console.log(view)
      this.modal_delete = true
      this.modalView = view
    },
  },
}
</script>

<style></style>
